<template>
  <div>
    <v-select
      label="text"
      :options="options"
      v-model="rowData.bedTypeID"
      :reduce="text => text.value"
      :clearable="false"
      :searchable="false"
    >
      <template #no-options>
        {{$t('common.no_options')}}
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import common from "@/common";

export default {
  name: "SlotBedType",
  components: {
    vSelect
  },
  props: {
    rowData: {type: Object},
    facilityId: {type: Number}
  },
  data() {
    return {
      options: []
    }
  },
  watch: {
    facilityId: function (facilityID, oldFacilityID) {
      if (oldFacilityID) {
        this.rowData.bedTypeID = null
        this.options = []
      }

      this.getBedTypeOptions(facilityID)
    }
  },
  mounted() {
    if (common.getAdminFacilityId()) {
      this.getBedTypeOptions(common.getAdminFacilityId())
    }
  },
  methods: {
    getBedTypeOptions: function (facility_id) {
      common.getBedTypeOptions(facility_id).then(res => {
        this.options = res
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
