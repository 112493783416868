import common from '@/common'

let form_options = {}
form_options.company = []
form_options.bed_type = []

export function reloadFieldData() {

  // init Arrays
  ;[
    form_options.company,
    form_options.bed_type,
  ].forEach(arr => arr.length = 0)

  // Todo: no need to call this whilst account is not admin
  common.apiGetData('/company/GetCompanyDropDownList')
    .then(res => {
      const company_options = common.getSelectOptions(res.data.companys, 'companyName', 'companyID')
      form_options.company.push.apply(form_options.company, company_options)
    })

  common.apiGetData('/bedtype/GetTypeDropDownList')
    .then(res => {
      const bed_type_options = common.getSelectOptions(res.data.bedtypes, 'type', 'bedTypeID')
      form_options.bed_type.push.apply(form_options.bed_type, bed_type_options)
    })
}

export default [
  {
    name: 'companyID',
    label: 'common.company',
    type: 'select',
    rule: 'required',
    options: form_options.company,
    show: common.isAdmin(),
    edit_disable: true
  },
  {
    name: 'facilityID',
    label: 'common.facility',
    type: 'slot',
    rule: 'required',
    slot: 'facility',
    show: common.isAdmin()
  },
  {
    name: 'floorID',
    label: 'common.floor',
    type: 'slot',
    rule: 'required',
    slot: 'floor',
  },
  {
    name: 'roomID',
    label: 'common.room',
    type: 'slot',
    slot: 'room',
    rule: 'required',
  },
  {
    name: 'bedNumber',
    label: 'bed.bedNumber',
    type: 'text',
    rule: 'required|max: 20',
  },
  {
    name: 'bedType',
    label: 'common.bed_type',
    type: 'slot',
    slot: 'bed_type',
    // rule: 'required',
  },
  {
    name: 'isActive',
    label: 'bed.isActive',
    type: 'select',
    rule: 'required',
    add_disable: true,
    options: [
      {
        text: common.getI18n('bed.active'),
        value: true
      },
      {
        text: common.getI18n('bed.passive'),
        value: false
      },
    ]
  },

]
